import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import university from '../../../util/image'
import CheckbooxButton from '../../../components/CheckbooxButton'
const DivBlurBottom = styled.div`
  overflow-y: scroll;
  position: relative;
  &:after { 
    content: '';
    position: fixed;
    left: 0px;
    right: 0px;
    height: 5%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
`
const CardUniversity = (props) => {
  // if (!university[props.hostid] || !university[props.hostname]) {
  //   console.log(props.hostid, props.hostname)
  // }
  return (
    <div className='w-full flex-row bg-white rounded-2xl drop-shadow-md'>
      <div className='grid grid-cols-12 gap-4 p-2'>
        <div className='col-span-6 my-auto flex flex-row'>
          <div className='hidden w-20 xl:flex flex-none'>
            <img className="mx-4" src={university[props.hostname]?.default ? university[props.hostname]?.default : university['empty'].default} alt="image for banner" width={'45px'} />
          </div>
          <div className='grow flex flex-col'>
            <div className='text-[#333] font-bold text-md'>{props.hostname}</div>
            <div className='text-[#999] font-bold text-sm'>{'จ.' + props.province_name + ' อ.' + props.city_name + ' ต.' + props.tumbon_name}</div>
          </div>
        </div>
        <div className='col-span-3 flex-col text-center'>
          <div className='text-[#333] font-bold text-lg'>
            <span className='text-center text-[#038967] font-bold mb-0 text-xl lg:text-2xl'>{props.sum_tps}</span>
          </div>
          <div className='text-[#333] font-bold text-md'>ได้รับทุน (คน)</div>
        </div>
        <div className='col-span-3 flex-col text-center'>
          <div className='text-[#333] font-bold text-lg'>
            <span className='text-center text-[#038967] font-bold mb-0 text-xl lg:text-2xl'>{props.major_group.length}</span>
          </div>
          <div className='text-[#333] font-bold text-md'>สาขาอาชีพ</div>
        </div>
      </div>
    </div>
  )
}


const CardTap = ({ arr, title, tab, onClick, istab }) => {
  return (<a className='col-span-6 xl:col-span-3 flex-col text-inherit' onClick={() => onClick(title)}>
    <div className={`relative w-full rounded-xl overflow-hidden shadow-lg border-solid border-4 ${tab === title ? 'border-green-500' : 'border-gray-200'} bg-white`}>
      <div className='w-full flex flex-col rounded-lg p-2'>
        {/* {title}<br /> จำนวนที่รับทุน  {arr.reduce(function (acc, obj) { return acc + obj.sum_vec }, 0)} คน */}
        <div className={`text-sm font-bold`}>{title}  < br /></div>
        <div className='text-sm text-[#999]'>สถาบัน <span className='text-green-500 text-lg font-bold'>{arr.length}</span> แห่ง</div>
        <div className='text-sm text-[#999]'>ได้รับทุน <span className='text-green-500 text-lg font-bold'>{arr.reduce(function (acc, obj) { return acc + obj.sum_tps }, 0)}</span> คน</div>
      </div>
    </div>
  </a >)
}

// const CardTap = ({ arr, title, tab, onClick, istab }) => {
//   return (<a className='col-span-6 xl:col-span-3 flex-col text-inherit' onClick={() => onClick(title)}>
//     <div className={`relative w-full rounded-xl overflow-hidden shadow-lg border-solid border-4 ${tab === title ? 'border-green-500' : 'border-gray-200'} bg-white`}>
//       <div className='w-full flex flex-col rounded-lg p-2'>
//         {/* {title}<br /> จำนวนที่รับทุน  {arr.reduce(function (acc, obj) { return acc + obj.sum_vec }, 0)} คน */}
//         <div className={`text-sm font-bold`}>{title}  < br /></div>
//         <div className='text-sm text-[#999]'>สถานศึกษา <span className='text-green-500 text-lg font-bold'>{arr.length}</span> แห่ง</div>
//         <div className='text-sm text-[#999]'>ได้รับทุน <span className='text-green-500 text-lg font-bold'>{arr.reduce(function (acc, obj) { return acc + obj.sum_tps }, 0)}</span> คน</div>
//       </div>
//     </div>
//   </a >)
// }



const Overview = (props) => {
  let { filter, setFilter } = props
  console.log("props = ", props)
  let {
    academyJoin,
    helpStudents2563,
    university2563,
  } = props.data

  let datas = [...props.data['university' + filter.year]]

  let [regionTab, setRegionTab] = useState('ทั้งหมด')
  let [show, setShow] = useState(datas.sort((a, b) => b.sum_tps - a.sum_tps))

  let [region, setRegion] = useState('ภาคกลาง')
  let north = datas.filter(x => x.region === 'ภาคเหนือ')
  let central = datas.filter(x => x.region === 'ภาคกลาง')
  let northeast = datas.filter(x => x.region === 'ภาคตะวันออกเฉียงเหนือ')
  let south = datas.filter(x => x.region === 'ภาคใต้')

  useEffect(() => {
    filter['region'] = regionTab
    setShow(datas.sort((a, b) => b.sum_tps - a.sum_tps).filter(x => x.region === regionTab || regionTab === 'ทั้งหมด'))
    setFilter({ ...filter })
  }, [regionTab])

  useEffect(() => {
    setShow(datas.sort((a, b) => b.sum_tps - a.sum_tps).filter(x => x.region === regionTab || regionTab === 'ทั้งหมด'))
  }, [filter])

  return (
    <div>
      <div className='w-[full] flex justify-center pb-4'>
        <div className='w-[500px] bg-white flex flex-row border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg justify-center' >
          <div className="mr-2" style={{ marginTop: 8, pading: 0 }}>ปีการศึกษา</div>
          <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
            filter.year = '2566'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
            filter.year = '2565'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
            filter.year = '2564'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
            filter.year = '2563'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2562' checked={filter.year === '2562'} onClick={() => {
            filter.year = '2562'
            setFilter({ ...filter })
          }} />
        </div>
      </div>

      <p className='text-center text-base lg:text-md xl:text-lg font-baijam'>
        ทุนพระกนิษฐาสัมมาชีพมีสถาบันที่เข้าร่วมโครงการกว่า <span className='text-[#0214B6] text-2xl'>{props.data['academyJoin' + filter.year].toLocaleString("en-US")}</span> สถาบัน ทั่วประเทศ <br />
        โดยมีกลุ่มเป้าหมายที่ได้รับทุน <span className='text-[#02A012] text-2xl'>{props.data['helpStudents' + filter.year].toLocaleString("en-US")}</span> คน

      </p>


      <div className='grid grid-cols-12 gap-1'>
        <CardTap arr={north} title={'ภาคเหนือ'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={northeast} title={'ภาคตะวันออกเฉียงเหนือ'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={south} title={'ภาคใต้'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
        <CardTap arr={central} title={'ภาคกลาง'} tab={regionTab} onClick={(tab) => tab === regionTab ? setRegionTab('ทั้งหมด') : setRegionTab(tab)} />
      </div>


      <DivBlurBottom className='grid grid-cols-12 gap-4 mt-4 max-h-[50vh] overflow-y-scroll'>
        {
          show.map(x => (
            <div className='col-span-12 lg:col-span-12 flex-col'>
              <CardUniversity {...x} />
            </div>
          ))
        }
      </DivBlurBottom>

    </div >
  )
}

export default Overview
