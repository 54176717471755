import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import imageMarker from '/images/marker.png';
import FilterTPS from './FilterTPS'
import CheckbooxButton from '../../../components/CheckbooxButton'

const DivBlurBottom = styled.div`
  width: 24rem;
  height: 650px;
  overflow-y: scroll;
  position: relative;
  &:after {
    content: '';
    position: fixed;
    left: 0px;
    right: 0px;
    height: 10%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
`

let MAP_TEXT = {
  'province_name': 'จังหวัด',
  'city_name': 'อำเภอ',
  'school': ''
}

const TitleTab = ({ data, filter, count }) => {
  let text = ''
  if (filter.view === 'country') {
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ทุนพระกนิษฐาสัมมาชีพได้ดำเนินงานครอบคลุมพื้นที่จังหวัด  <span className='text-[#0214B6] text-2xl'>{data[`coverProvince${filter.year}`].toLocaleString("en-US")}</span> จังหวัด  <br />
        มีกลุ่มเป้าหมายที่ได้รับทุน <span className='text-[#02A012] text-2xl'> {count.sumtps.toLocaleString("en-US")}</span> คน
        เข้ารับการศึกษาใน<span className='text-[#02A012] text-2xl'> {count.count_tpsschool.toLocaleString("en-US")}</span> สถาบัน
      </p>)
  } else if (filter.view === 'province') {
    // 'จังหวัด' + filter.province_name
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ทุนพระกนิษฐาสัมมาชีพได้ดำเนินงานครอบคลุมพื้นที่จังหวัด{filter.province_name} ใน <span className='text-[#0214B6] text-2xl'>{count.count_city.toLocaleString("en-US").toLocaleString("en-US")}</span>  อำเภอ  <br />
        มีกลุ่มเป้าหมายที่ได้รับทุน <span className='text-[#02A012] text-2xl'> {count.sumtps.toLocaleString("en-US")}</span> คน
        เข้ารับการศึกษาใน<span className='text-[#02A012] text-2xl'> {count.count_tpsschool.toLocaleString("en-US")}</span> สถาบัน
      </p>)
  } else {
    {
      return (
        <p className='text-center text-sm xl:text-lg font-baijam'>
          ทุนพระกนิษฐาสัมมาชีพได้ดำเนินงานครอบคลุมพื้นที่ จ.{filter.province_name} อ. {filter.city_name}  ใน <span className='text-[#0214B6] text-2xl'>{count.count_tumbon.toLocaleString("en-US").toLocaleString("en-US")}</span> ตำบล <br />
          มีกลุ่มเป้าหมายที่ได้รับทุน <span className='text-[#02A012] text-2xl'> {count.sumtps.toLocaleString("en-US")}</span> คน
          เข้ารับการศึกษาใน<span className='text-[#02A012] text-2xl'> {count.count_tpsschool.toLocaleString("en-US")}</span> สถาบัน
        </p>)
    }
  }
}

const CardItem = (props) => {
  return (<div
    onClick={() => props.onClickButton(props)}
    className='w-11/12 justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 mx-2 mb-4 bg-white hover:bg-gray-100'
  >
    <div className='flex flex-row justify-between'>
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          <span className='text-md font-bold text-center'>{MAP_TEXT[props.type]}{props.key_group}</span>
        </div>
      </div>
    </div>
    <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>


    <div className='flex flex-row justify-between '>
      {
        props.type === 'school' ?
          <>
            <div className='flex flex-row'>
              <span className='text-lg font-bold text-left mr-2'>จำนวนผู้ที่ได้รับทุน</span>
              <span className='text-lg font-bold text-left text-green-500'>  {props.sum_tps.toLocaleString("en-US")} คน</span>
            </div>
          </>
          :
          <>
            <div className='flex flex-col'>
              <span className='text-md font-bold text-left'>จำนวนสถาบัน</span>
              <span className='text-lg font-bold text-left text-green-500'>{props.sum_school} สถาบัน</span>
            </div>

            <div className='flex flex-col'>
              <span className='text-md font-bold text-right'>จำนวนผู้ที่ได้รับทุน</span>
              <span className='text-lg font-bold text-right text-blue-500'>{props.sum_tps.toLocaleString("en-US")} คน</span>
            </div>
          </>
      }

    </div>
  </div>)
}


const MapAreaView = (props) => {

  let { filter, setFilter } = props

  const [datas, setDatas] = useState([]);

  const [data, setData] = useState({
    count_province_north: 0,
    count_province_central: 0,
    count_province_northeast: 0,
    count_province_south: 0,

    count_city: 0,
    count_tumbon: 0,

    tpsNorth: 0,
    schoolNorth: 0,
    tpsCentral: 0,
    schoolCentral: 0,
    tpsNortheast: 0,
    schoolNortheast: 0,
    tpsSouth: 0,
    schoolSouth: 0,
    sumtps: 0,
    tpsschool: 0,
    name: ''
  });

  // console.log("school_ori2564", school2563)

  useEffect(() => {

    let result = {}
    let _data = []
    let _dataForSum = []
    let key_group = 'province_name'
    let name = ''
    // school_ori2564

    _dataForSum = [...props.data['university' + filter.year]]
    _data = [...props.data['university' + filter.year]]
    console.log("_data", _data)
    console.log("key_group", _data)


    if (filter.view === 'country') {
      key_group = 'province_name'
      _data = _data.map(x => ({ ...x, type: 'province_name' }))
    }

    if (filter.view === 'province') {
      key_group = 'city_name'
      name = 'จังหวัด' + filter.province_name
      _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
    }

    if (filter.view === 'city') {
      key_group = 'hostname'
      name = 'อำเภอ' + filter.city_name
      _data = _data
        .filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => ({ ...x, type: 'school' }))
    }




    // if (filter.view === 'country') {
    //   key_group = 'province_name'
    //   _data = _data.map(x => ({ ...x, type: 'province_name' }))
    // }

    // if (filter.view === 'province') {
    //   key_group = 'city_name'
    //   name = 'จังหวัด' + filter.province_name
    //   _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
    // }

    // if (filter.view === 'city') {
    //   key_group = 'hostname'
    //   name = 'อำเภอ' + filter.city_name
    //   _data = _data
    //     .filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => ({ ...x, type: 'school' }))
    // }





    _data.forEach(element => {
      if (!result[element[key_group]]) {
        result[element[key_group]] = {
          sum_school: 1,
          key_group: element[key_group],
          sum_tps: element.sum_tps,
          type: element.type
        }
        if (element.type === 'school') {
          result[element[key_group]]['data'] = element
        }
      } else {
        result[element[key_group]].sum_school += 1
        result[element[key_group]].sum_tps += element.sum_tps
      }
    });
    console.log('result',result)
    setDatas([...Object.values(result)])
    setData({
      count_province_north: [... (new Set(_dataForSum.filter(x => x.region === "ภาคเหนือ").map(x => x.province_name)))].length,
      count_province_central: [... (new Set(_dataForSum.filter(x => x.region === "ภาคกลาง").map(x => x.province_name)))].length,
      count_province_northeast: [... (new Set(_dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").map(x => x.province_name)))].length,
      count_province_south: [... (new Set(_dataForSum.filter(x => x.region === "ภาคใต้").map(x => x.province_name)))].length,

      count_city: [... (new Set(_dataForSum.filter(x => x.province_name === filter.province_name).map(x => x.city_name)))].length,
      count_tumbon: [... (new Set(_dataForSum.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => x.tumbon_name)))].length,

      tpsNorth: _dataForSum.filter(x => x.region === "ภาคเหนือ").reduce((acc, obj) => obj.sum_tps + acc, 0),
      schoolNorth: _dataForSum.filter(x => x.region === "ภาคเหนือ").length,
      tpsCentral: _dataForSum.filter(x => x.region === "ภาคกลาง").reduce((acc, obj) => obj.sum_tps + acc, 0),
      schoolCentral: _dataForSum.filter(x => x.region === "ภาคกลาง").length,
      tpsNortheast: _dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").reduce((acc, obj) => obj.sum_tps + acc, 0),
      schoolNortheast: _dataForSum.filter(x => x.region === "ภาคตะวันออกเฉียงเหนือ").length,
      tpsSouth: _dataForSum.filter(x => x.region === "ภาคใต้").reduce((acc, obj) => obj.sum_tps + acc, 0),
      schoolSouth: _dataForSum.filter(x => x.region === "ภาคใต้").length,
      sumtps: [...Object.values(result)].reduce((acc, obj) => obj.sum_tps + acc, 0),
      tpsschool: [...Object.values(result)].reduce((acc, obj) => obj.sum_school + acc, 0),
      name
    })

  }, [filter])

  return (
    <div>

      {/* <p className='text-center text-base lg:text-lg'>
        ทุนพระกนิษฐาสัมมาชีพได้ดำเนินงานครอบคลุมพื้นที่จังหวัด  <span className='text-[#0214B6] text-2xl'>{coverProvince.toLocaleString("en-US")}</span> จังหวัด  <br />
        มีกลุ่มเป้าหมายที่ได้รับทุน<span className='text-[#02A012] text-2xl'> {helpStudents2563.toLocaleString("en-US")}</span> คน
        เข้ารับการศึกษาใน<span className='text-[#02A012] text-2xl'> {academyJoin.toLocaleString("en-US")}</span> สถาบัน
      </p> */}
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-col md:flex-row  border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
            <div className=' flex flex-col mb-0 md:mb-2' >
              <h5 className="text-sm m-0 ml-[9px]" >ปีการศึกษา: </h5>
              <div className='flex flex-row' >
                <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
                  filter.year = '2566'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
                  filter.year = '2565'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                  filter.year = '2564'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                  filter.year = '2563'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2562' checked={filter.year === '2562'} onClick={() => {
                  filter.year = '2562'
                  setFilter({ ...filter })
                }} />
              </div>
            </div>
            < FilterTPS
              data={filter}
              onChange={(res) => {
                setFilter({ ...filter, ...res })
              }}
            />
          </div>
        </div>
      </div>

      <TitleTab
        data={props.data}
        filter={filter}
        count={{
          count_city: data.count_city,
          count_tumbon: data.count_tumbon,
          count_tpsschool: data.tpsschool,
          sumtps: data.sumtps
        }} />

      <div className="flex flex-col md:flex-row xl:mx-12">
        <div className='w-full md:w-96 h-min '>
          {filter.view === 'country' ?
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_north}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนสถาบัน  <span className='text-md font-bold text-center text-blue-600'>{data.schoolNorth}</span>  สถาบัน</div>
              <div className="ml-6">จำนวนผู้ที่ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.tpsNorth}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคตะวันออกเฉียงเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_northeast}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนสถาบัน  <span className='text-md font-bold text-center text-blue-600'>{data.schoolNortheast}</span>  สถาบัน</div>
              <div className="ml-6">จำนวนผู้ที่ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.tpsNortheast}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคใต้</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_south}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนสถาบัน  <span className='text-md font-bold text-center text-blue-600'>{data.schoolSouth}</span>  สถาบัน</div>
              <div className="ml-6">จำนวนผู้ที่ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.tpsSouth}</span>  ทุน</div>

              <div className='w-[200px] flex flex-row mt-6 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>ภาคกลาง</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_central}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนสถาบัน  <span className='text-md font-bold text-center text-blue-600'>{data.schoolCentral}</span>  สถาบัน</div>
              <div className="ml-6">จำนวนผู้ที่ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.tpsCentral}</span>  ทุน</div>
            </div>
            :
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} style={{}} />
                <div className='text-md font-bold text-center'>{data.name}</div>
              </div>
              {console.log(">> filter.view ", filter.view)}
              {filter.view === 'province' ? <div className="ml-6">จำนวนอำเภอ  <span className='text-md font-bold text-center text-blue-600'>{data.count_city}</span> อำเภอ</div> : <></>}
              <div className="ml-6">จำนวนสถาบัน  <span className='text-md font-bold text-center text-blue-600'>{data.tpsschool}</span>  สถาบัน</div>
              <div className="ml-6">จำนวนผู้ที่ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.sumtps}</span>  ทุน</div>
            </div>

          }
        </div>
        <DivBlurBottom>
          {datas.map(x => (<CardItem {...x}
            onClickButton={(res) => {
              if (x.type === 'province_name') {
                setFilter({
                  ...filter,
                  ...{
                    view: 'province',
                    province_name: res.key_group
                  }
                })
              } else if (x.type === 'city_name') {
                console.log('DivBlurBottom: ', x)
                setFilter({
                  ...filter,
                  ...{
                    view: 'city',
                    city_name: res.key_group
                  }
                })
              } else if (x.type === 'school') {
                props.ShowModalSchool({ ...x.data, ... { tumbon: x.data.tumbon_name, city: x.data.city_name } })
              }
            }}
          />))}
        </DivBlurBottom>
      </div>
    </div >
  )
}

export default MapAreaView
