import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Chart'
import imageTpsSchool from '/images/tps-3.png';
import iimageTpsStudentsCapital from '/images/vec-1.png';
import imageTpsProvinceJoin from '/images/tps-6.png';
import imageTpsAcademyJoin from '/images/tps-7.png';
import CheckbooxButton from '../../../components/CheckbooxButton'
const Overview = (props) => {
  const [data, setData] = useState(props.data);
  let { filter, setFilter } = props
  return (
    <div>
      <p className='text-center text-base lg:text-lg font-baijam'>
        {/* ตั้งแต่ปีการศึกษา 2563 จนถึงปัจจุบันทุนพระกนิษฐาสัมมาชีพ <br /> */}
        ทุนพระกนิษฐาสัมมาชีพได้ช่วยเหลือกลุ่มเป้าหมายในปีการศึกษา 2562 - 2566<br />
        มีกลุ่มเป้าหมายที่ได้รับทุนจำนวน <span className='text-[#038967] text-2xl'>{(data['helpStudents2562'] + data['helpStudents2563'] + data['helpStudents2564'] + data['helpStudents2565'] + data['helpStudents2566']).toLocaleString("en-US")}</span> คน ได้เข้ารับการศึกษาใน <span className='text-[#038967] text-2xl'>{data['academyJoin'].toLocaleString("en-US")}</span>  สถาบัน
      </p>

      <div className='grid grid-cols-12 gap-4'>

        <div className='col-span-12 lg:col-span-12 bg-white'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <Chart
              containerProps={{ style: { height: "200px" } }}
              className='p-4'
              options={{
                type: 'areaspline',
                title: 'จำนวนนักเรียนที่ได้รับทุนตั้งแต่ปี 2562 ถึง 2566',
                categories: ['2562', '2563', '2564', '2565', '2566'],
                yAxis: {
                  title: {
                    text: 'นร.ทุนพระกนิษฐาสัมมาชีพ (คน)',
                  }
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                },
              }}
              data={
                [{
                  name: 'นร.ทุนพระกนิษฐาสัมมาชีพ',
                  marker: {
                    symbol: 'square'
                  },
                  data: [data.helpStudents2562, data.helpStudents2563, data.helpStudents2564, data.helpStudents2565, data.helpStudents2566],
                  color: '#ffb280'
                }]
              } />
          </div>
        </div>

        <div className='col-span-12 lg:col-span-12'>
          <div className='w-[full] flex justify-center mt-6'>
            <div className='w-[460px] bg-white flex flex-row border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
              <div className="mr-2" style={{ marginTop: 8, pading: 0 }}>ปีการศึกษา</div>
              <CheckbooxButton title='2566' checked={filter.year === '2566'} onClick={() => {
                filter.year = '2566'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2565' checked={filter.year === '2565'} onClick={() => {
                filter.year = '2565'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                filter.year = '2564'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                filter.year = '2563'
                setFilter({ ...filter })
              }} />
              <CheckbooxButton title='2562' checked={filter.year === '2562'} onClick={() => {
                filter.year = '2562'
                setFilter({ ...filter })
              }} />
            </div>

          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4" src={iimageTpsStudentsCapital} alt="image for banner" width={'64px'} />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>นักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data['helpStudents' + filter.year].toLocaleString("en-US")}</p>
              <span className='text-md font-bold text-center'>คน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4" src={imageTpsProvinceJoin} alt="image for banner" width={'64px'} />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>ครอบคลุม</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data['coverProvince' + filter.year]}</p>
              <span className='text-md font-bold text-center'>จังหวัด</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4" src={imageTpsAcademyJoin} alt="image for banner" width={'64px'} />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>สถาบันที่เข้าร่วม</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data['academyJoin' + filter.year]}</p>
              <span className='text-md font-bold text-center'>สถาบัน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4" src={imageTpsSchool} alt="image for banner" width={'64px'} />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>สาขาที่สนับสนุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data['university' + filter.year].reduce((acc, cur) => acc + cur.major_group.length, 0)}</p>
              <span className='text-md font-bold text-center'>สาขาอาชีพ</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
